import Link from 'next/link';
import styles from './Footer.module.css';

export function Footer() {
  return (
    <div className={styles.Footer}>
      <p>
        Reach out if you want me to talk at your conference or are seeking
        mentorship/coaching.
      </p>

      <ul className={styles.LinksList}>
        <li className={styles.Link}>
          <a href="mailto:kaelig@deloumeau.fr">kaelig@deloumeau.fr</a>
        </li>

        <li>
          <Link href="https://twitter.com/kaelig">
            <a>twitter</a>
          </Link>
        </li>
        <li>
          <Link href="https://www.linkedin.com/in/kaelig/en">
            <a>LinkedIn</a>
          </Link>
        </li>
      </ul>

      <div className={styles.Verybottom}>
        <div>Design by Saurabh</div>

        <div className={styles.sosumi}>
          Copyright © 2021 Kaelig Deloumeau-Prigent. All Rights Reserved
        </div>
      </div>
    </div>
  );
}
