import clsx from 'clsx';
import Link from 'next/link';
import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  href: string;
  size?: 'default' | 'small';
}

export function Button({
  children,
  href,
  size = 'default',
}: Props): JSX.Element {
  return (
    <Link href={href}>
      <a className={clsx(styles.Button, styles[`size--${size}`])}>
        <span className={styles.text}>{children}</span>
        <span className={styles.inner} />
        <span className={styles.outer} />
      </a>
    </Link>
  );
}
